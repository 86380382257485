import React from "react"

// Styles
import * as styles from "./FaqsBlock.module.scss"

const FaqsBlock = ({ faqs }) => {
    console.log("FaqsBlock props:", faqs);

    // Ensure faqs exist and extract the correct array
    const faqItems = faqs?.faqs && Array.isArray(faqs.faqs) ? faqs.faqs : [];

    if (faqItems.length === 0) {
        return null;
    }

    return (
        <div className={styles.FaqsBlock}>
            <h2>FAQs</h2>
            {faqItems.map((faq, index) => (
                <details key={index}>
                    <summary>
                        {faq.question}
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none">
                            <circle cx="14.5" cy="14.5" r="14.5" fill="#949494" opacity=".25" />
                            <path fill="#262626" d="m8 16.06 1.936-1.936 4.48 4.48 4.482-4.48 1.936 1.936-6.417 6.417L8 16.06Z" />
                            <path fill="#262626" d="M15.94 20.628V7h-3.042v13.628h3.042Z" />
                        </svg>
                    </summary>
                    <section className="accordion-inner">
                        <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </section>
                </details>
            ))}
        </div>
    );
};

export default FaqsBlock;
